import React from 'react';
import { useTranslation } from 'react-i18next';
import translations from '~src/internationalisation/translation-map.json';
import { probabilities } from '~src/enums/simulations';
import { BarChart } from '@oliasoft-open-source/charts-library';
import {
  getDurationOrCost,
  getMetersPerDay,
} from '~store/entities/simulations/simulations';
import { round } from '@oliasoft-open-source/units';
import { SimulationChartType } from '~src/enums/campaigns';
import { withErrorBoundary } from '~src/common/error-boundary/error-boundary';
import { capitalize } from 'lodash';
import { Spacer, Flex, Message } from '@oliasoft-open-source/react-ui-library';

const RawSummaryChart = ({
  campaign,
  probability,
  dataEntity,
  primaryCurrency,
  chartType,
  controlsPortalId,
}) => {
  const { t } = useTranslation();

  const formatProbabilityString = () => {
    const prefix = probability === probabilities.MEAN ? '' : 'P';
    const capitalizedProbability = `${prefix}${capitalize(probability)}`;

    if (dataEntity === 0) {
      return `${capitalizedProbability} ${t(translations.days)}`;
    } else {
      return `${capitalizedProbability} ${t(
        translations.cost,
      )} [M${primaryCurrency}]`;
    }
  };

  const yLabel =
    chartType === SimulationChartType.METERS_PER_DAY
      ? t(translations.campaigns_metersPerDay)
      : formatProbabilityString();

  const options = {
    chartStyling: {
      width: '80%',
    },
    legend: {
      position: 'top-left',
    },
    axes: {
      y: [
        {
          label: yLabel,
        },
      ],
    },
    additionalAxesOptions: {
      stackedX: true,
    },
    tooltip: {
      scientificNotation: false,
    },
    graph: {
      showDataLabels: true,
    },
  };

  const labels = [];
  const adjustedDuration = [];
  const datasetData = [];
  const backgroundColor = [];

  let totalAdjusted = 0;

  switch (chartType) {
    case SimulationChartType.TOTAL_DAYS: {
      campaign.estimates.reduce((acc, curr, index) => {
        const previousValue = index === 0 ? 0 : acc[index - 1]?.[1];
        const duration =
          getDurationOrCost(curr.simulation, probability, dataEntity) ?? 0;
        labels.push(curr.name);
        if (campaign.learningCurve) {
          adjustedDuration.push([
            previousValue,
            previousValue + round(duration * (1 - curr.adjustment / 100), 3),
          ]);
        }
        const newValue = round(previousValue + duration, 2);
        if (newValue > totalAdjusted) {
          totalAdjusted = newValue;
        }
        backgroundColor.push('hsl(0, 0%, 83%)');

        acc.push([previousValue, newValue]);
        return acc;
      }, datasetData);

      if (datasetData.length > 0) {
        datasetData.push(totalAdjusted);
        backgroundColor.push('hsl(60, 32%, 65%)');
        labels.push(t(translations.campaigns_cumulativeTotal));
      }

      if (campaign?.campaignTotal?.simulation) {
        const totalDuration = getDurationOrCost(
          campaign?.campaignTotal?.simulation,
          probability,
          dataEntity,
        );
        datasetData.push(totalDuration);
        backgroundColor.push('hsl(61, 33%, 70%)');
        labels.push(t(translations.campaigns_campaignTotal));
      }

      break;
    }
    case SimulationChartType.METERS_PER_DAY: {
      campaign.estimates.forEach((estimate) => {
        const duration =
          getDurationOrCost(estimate.simulation, probability, dataEntity) ?? 0;
        const metersPerDay =
          getMetersPerDay(estimate.simulation, duration, probability) ?? 0;
        backgroundColor.push('hsla(61, 33%, 60%, 0.85)');

        labels.push(estimate.name);
        if (campaign.learningCurve) {
          adjustedDuration.push(
            round(duration * (1 - estimate.adjustment / 100), 3),
          );
        }

        datasetData.push(metersPerDay);
      });
      break;
    }
    default: {
      break;
    }
  }
  const label = Object.keys(probabilities).find(
    (key) => probabilities[key] === probability,
  );

  const formatToMillions = (num) => round(num / 1_000_000, 2);
  const costData = datasetData.map((value) => {
    if (Array.isArray(value)) {
      return value.map(formatToMillions);
    } else {
      return formatToMillions(value);
    }
  });

  const data = {
    labels,
    datasets: [
      {
        data: dataEntity === 0 ? datasetData : costData,
        label,
        backgroundColor,
        barPercentage: 0.85,
      },
      ...(campaign.learningCurve
        ? [
            {
              data: adjustedDuration,
              label: `Adjusted ${label}`,
              backgroundColor: 'hsla(60, 20%, 50%, 0.85)',
              barPercentage: 1,
            },
          ]
        : []),
    ],
  };

  return datasetData.length === 0 ? null : (
    <>
      <BarChart chart={{ data, options, controlsPortalId }} />
      {(!campaign.campaignTotal.simulation ||
        campaign.campaignTotal.simulation.statusCode === 404) && (
        <>
          <Spacer />
          <Flex direction="column">
            <Message
              message={{
                content: t(translations.campaigns_campaignTotalIsLoading),
                type: 'Info',
                visible: true,
              }}
            />
          </Flex>
        </>
      )}
    </>
  );
};

export const SummaryChart = withErrorBoundary(RawSummaryChart);
